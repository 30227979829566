@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #575757;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #575757;
}
/* .highlighter-main {
  background-image: url("https://learnwithsumit.com/_next/static/media/underline.933c12d8.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-position: bottom;
} */
/*  media queries*/
@media (min-width: 768px) {
  .circle-sketch-highlight {
    position: relative;
    /* left: 10px; */
  }
  .circle-sketch-highlight:before {
    content: "";
    z-index: -1;
    left: -9px;
    top: -5px;
    border-width: 3px;
    border-style: solid;
    border-color: #793ef9;
    position: absolute;
    border-right-color: transparent;
    width: 100%;
    height: 1.5em;
    transform: rotate(2deg);
    opacity: 0.9;
    border-radius: 100%;
    padding: 0.1em 0.25em;
    transition: all 0.2s ease-in-out;
  }
  .circle-sketch-highlight:after {
    content: "";
    z-index: -1;
    left: -5px;
    top: 5px;
    padding: 0.1em 0.25em;
    border-width: 3px;
    border-style: solid;
    border-color: #793ef9;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    width: 100%;
    height: 1.5em;
    transform: rotate(-1deg);
    opacity: 0.9;
    border-radius: 100%;
  }
}

@media (min-width: 768px) {
  .circle-sketch-highlight:hover {
    transform: scale(1.1);
  }
  .circle-sketch-highlight:hover:before {
    transform: rotate(5deg);
  }
  .circle-sketch-highlight:hover:after {
    transform: rotate(-5deg);
  }
}

span {
  white-space: normal;
}