.heroLightPattern {
  background: url("../../assets/bg-light-md.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
}

.heroWhiteBg {
  opacity: 1;
  background-image: linear-gradient(#e5e5e5 1.5px, transparent 1.5px),
    linear-gradient(to right, #e5e5e5 1.5px, #ffffff 1.5px);
  background-size: 25px 25px;
}

.heroDarkBg {
  opacity: 1;
  background-image: linear-gradient(#e5e5e5 1.5px, transparent 1.5px),
    linear-gradient(to right, #e5e5e5 1.5px, transparent 1.5px);
  background-size: 25px 25px;
}

.heroDarkPattern {
  background: url("../../assets/bg-dark-md.jpg");
  opacity: 0.9;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
